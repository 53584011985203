$theme-color: #e22e33;
$active-theme-color: #c21b20;

.XMModalWrapper {
  height: auto !important;
  max-height: 95vh;

  :global {
    .br-modal__header {
      position: relative;
      height: 74px;
      font-size: 20px;
      font-weight: bold;
      color: #413e53;

      .br-modal__header--title {
        width: calc(100% - 16px) !important;
        height: 100%;
        line-height: 74px;
      }

      .br-modal__close-icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 74px;
        font-size: 20px;
        line-height: 74px;
        color: $theme-color !important;
        text-align: center;
        opacity: 1;

        &:hover {
          color: $theme-color !important;
        }
      }
    }

    .br-modal__content {
      max-height: calc(95vh - 166px);
      padding: 22px 80px 50px 80px;
      font-size: 14px;
    }

    .br-modal__footer {
      padding-bottom: 50px;
    }
  }
}

.hideModalContent {
  padding-bottom: 0 !important;
}

.contentTips {
  text-align: center;
  word-break: break-all;
}

.modalBtn {
  min-width: 110px;
  padding: 0 10px;
  text-align: center;
}

.confirmBtn {
  color: #fff;
  background: $theme-color;

  &:hover {
    background: $active-theme-color;
    border-color: transparent;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #d5d5d5;
    border-color: transparent;
  }
}

.cancelBtn {
  &:hover {
    color: $theme-color;
    border-color: $theme-color;
  }
}
