$navbar-font-color: #888;
$topbar-tool-info-color: #c8cad2;

.brs-seller-navbar {
  z-index: $navbar-z-index;
  height: $navbar-height;
  padding: $navbar-padding-y $navbar-padding-x;
  font-size: 18px;
  font-weight: bold;
  line-height: 40px;
  background-color: white;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.06);
}
