$user-wrapper-padding-x: 10px;
$user-wrapper-padding-y: 24px;
$user-avatar-size: 44px;

.sidebar {
  position: relative;
  width: $sidebar-width;
  height: 100%;
  min-height: 600px;
  padding: 44px 20px 20px 0;
  overflow-x: hidden;
  overflow-y: auto;
  color: #e00030;
  background: #fff;
  border-radius: 4px;
  box-shadow: 4px 0 6px 0 rgba(220, 220, 232, 0.35);

  .user-wrapper {
    padding: $user-wrapper-padding-y $user-wrapper-padding-x;
    background-size: 100%;
  }

  .user-avatar {
    display: inline-block;
    width: $user-avatar-size;
    height: $user-avatar-size;
    vertical-align: middle;
    border-radius: 50%;
  }

  .user-name {
    display: inline-block;
    width:
      $sidebar-width - $user-avatar-size - 2 * $user-wrapper-padding-x -
      $scroll-bar-width;
    width: 86px;
    padding-left: 8px;
    font-size: 14px;
    vertical-align: middle;
  }
}

.blm-side__bar-logo {
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 100%;
  text-align: center;
  cursor: pointer;
  transform: translateX(-50%);

  img {
    width: 160px;
  }

  .blm-side__weapp-code {
    font-size: 14px;
    color: #5d5978;

    img {
      width: 120px;
    }
  }
}
