$navbar-font-color: #888;
$topbar-tool-info-color: $white;

.brs-seller-topbar {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: $topbar-height;
  padding-right: 30px;
  padding-left: 20px;
  color: $white;
  background-color: #e00030;

  .auto-space {
    flex: 1;
  }

  .brs-seller__route {
    display: flex;

    .brs-seller__route-item {
      padding: 7px 0;
      margin: 0 30px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      cursor: pointer;
      border-bottom: 2px solid transparent;
    }

    .brs-seller__route-item:first-child {
      margin-left: 85px;
    }

    .brs-seller__route-item--active {
      border-bottom: 2px solid #fff;
    }
  }

  .info {
    height: 32px;
    margin: 0 20px 0 10px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 16px;
    color: rgba(255, 255, 255, 1);
  }

  .brs-seller-logo {
    width: 155px;
    height: $topbar-height - 14px;
    margin-top: 6px;
    cursor: pointer;
    background: url('../assets/images/logo.png') no-repeat;
    background-size: contain;
  }

  &__tool-container {
    float: right;
    line-height: 58px;
  }

  &__tool-title {
    position: relative;
    padding: 0 10px;
    color: $topbar-tool-info-color;

    &.pointer {
      cursor: pointer;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 14px;
      content: '';
      background-color: #666;
    }
  }
}

// 小马代发pc
.platformNameTips {
  font-size: 12px;
}

.userPopoverBox {
  .userName {
    height: 45px;
    padding: 0 18px;
    overflow: hidden;
    font-size: 12px;
    line-height: 45px;
    color: #5d5978;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #f6f6fb;
  }
}

.userDetailPopover {
  width: 140px;
  height: 94px;
  padding: 0;
  margin-top: 0 !important;
  color: #5d5978;
  border-radius: 4px;
}

.top-bar__login-out {
  width: 90px;
  padding: 15px 18px;
  cursor: pointer;
}

.top-bar__login-out:hover {
  color: #e00030;
}

.login-out__text {
  margin-left: 4px;
}

.login-out__icon {
  float: left;
  font-size: 12px;
}

.top_bar--user__name {
  float: left;
  max-width: 100px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

.loginOutPopover {
  cursor: pointer;
}
