body {
  font-family: "Microsoft Sans Serif", "PingFang SC", Helvetica, Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

html,
body,
#root,
#root>* {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0 auto;
  overflow: hidden;
  background-color: #eef4f9;
}

#root {
  position: relative;
}

.brs-main {
  display: flex;
  height: 100%;
  padding-top: $topbar-height;
}

.brs-page {
  height: 100%;
  margin-left: $sidebar-width + 10px;
  overflow-x: hidden;
  overflow-y: auto;
  background: $content-bg;

  &--has-navbar {
    .brs-page__content {
      padding-top: $page-padding-x;
    }
  }

  &__content {
    position: relative;
    padding: $page-padding-y $page-padding-x;
    padding-right: $page-padding-x - $scroll-bar-width;
    padding-bottom: $page-padding-y + $copyright-height;
  }

  &__content__wrapper {
    position: relative;
    min-height: 100%;
  }

  &__breadcrumb {
    margin: 0;
  }
}

// 修复 cascader 组件 弹出层 被遮挡的问题。
.dropdown-wrapper.br-cascader__popup-wrapper {
  position: fixed;
  z-index: 1000;
}
