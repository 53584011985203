$primary-color: #e22e33 !default;
$primary-active-color: #e00030 !default;
$success-color: #43b52c !default;
$info-color: #0a67b0 !default;
$link-color: #0a67b0 !default;
$warn-color: #ffa024 !default;
$white: #fff !default;
$admin-sidebar-bg: #2b3240 !default;

// 默认文字颜色: 用于标题、文本信息描述
$text-color: #262626 !default;
// 文字次色: 用于信息描述、提示信息、辅助信息
$text-secondary-color: #666 !default;
// 文字辅助色: 用于备注、辅助提示、占位文字
$text-help-color: #999 !default;

// 侧边栏宽度
$sidebar-width: 200px !default;
// 导航图高度
$navbar-height: 60px !default;
// topbar 高度
$topbar-height: 60px !default;

// navbar 高度
$navbar-height: 68px;
$navbar-padding-x: 20px;
$navbar-padding-y: 13px;
$navbar-z-index: 100;

// content background 主内容区背景色
$content-bg: #eef4f9 !default;

$page-padding-x: 20px !default;
$page-padding-y: 16px !default;

$copyright-height: 36px !default;

// scrollbar 宽度
$scroll-bar-width: 10px !default;

$sidebar-background: #2a2d3f !default;
$topbar-background: #2a2d3f !default;
