.br-input--sm {
  height: 26px !important;
}

.br-input__input {
  line-height: 38px;
  color: #413e53;
}

.br-cascader {
  height: 40px;

  .br-cascader__value {
    height: 40px;
    font-size: 12px;
    line-height: 40px;
    color: #413e53;
    border-color: #e9e9ef;
    border-radius: 4px;
  }
}

.br-input {
  height: 40px;
  border-color: #e9e9ef;
  border-radius: 4px;
}

.button-lg {
  width: 110px;
  height: 42px;
}

.br-modal {
  border-radius: 4px;
}

.br-modal .br-modal__header .br-modal__close-icon {
  color: #e22e33;
  opacity: 1;
}

body::-webkit-scrollbar-track-piece,
.br-datatable-scroll .table-body::-webkit-scrollbar-track-piece {
  //滚动条凹槽的颜色，还可以设置边框属性
  background-color: rgba(233, 235, 238, 1);
}

body::-webkit-scrollbar,
.br-datatable-scroll .table-body::-webkit-scrollbar {
  //滚动条的宽度
  width: 4px;
  height: 4px;
}

body::-webkit-scrollbar-thumb,
.br-datatable-scroll .table-body::-webkit-scrollbar-thumb {
  min-height: 28px;
  //滚动条的设置
  background-color: rgba(169, 173, 184, 1);
  background-clip: padding-box;
  border-radius: 4px;
}

.commonScrollBar::-webkit-scrollbar-track-piece { //滚动条凹槽的颜色，还可以设置边框属性
  background-color: rgba(233, 235, 238, 1);
}

.commonScrollBar::-webkit-scrollbar {//滚动条的宽度
  width: 4px;
  height: 4px;
}

.commonScrollBar::-webkit-scrollbar-thumb {//滚动条的设置
  min-height: 28px;
  background-color: rgba(169, 173, 184, 1);
  background-clip: padding-box;
  border-radius: 4px;
}

.clear-table-style {
  .br-datatable {
    padding: 0;
    box-shadow: 0 0 0 0 rgba(220, 220, 232, 0.35);
  }
}

// .br-datatable-scroll .table-body::-webkit-scrollbar-thumb:hover {
//   background-color:#bbb;
// }

.br-cascader__options {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;

  .br-cascader__option {
    display: -webkit-box;
    width: 25%;
    height: 18px;
    padding: 0 8px;
    margin: 8px 0;
    overflow: hidden;
    line-height: 18px;
    text-align: center;
    text-overflow: ellipsis;
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre-wrap !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
